import { CheckIcon } from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Contact management',
    description: 'Manage your contacts in a single place. Import your contacts from more than 5000+ apps.',
  },
  { name: 'Email templates',
    description: 'Create beautiful emails with our drag-and-drop email builder. Use our email templates to get started quickly.',
  },
  {
    name: 'Email campaigns',
    description: 'Send your emails to your contacts. Track your open rates, click rates, and more.',
  },
  {
    name: 'Worflows and automation',
    description: 'Send your emails at the right time with our email automation.',
  },

  { name: 'WhatsApp message templates',
    description: 'Manage your WhatsApp message templates in a single place.',
  },
  {
    name: 'WhatsApp campaigns',
    description: 'Bulk send your WhatsApp messages to your contacts. Track your read rates, click rates, and more.',
  },
  {
    name: "Email deliverability assistance",
    description: "Get your emails delivered to your customers' inbox with our deliverability assistance.",
  },
  {
    name: "Transactional emails",
    description: "Send your transactional emails through our SMTP relay.",
  },{
    name: 'API integrations',
    description: 'Integrate with your favorite tools like Shopify, Zoho and 5000+ apps through zapier. Build your own integrations with our API.',
  },{
    name: "Custom integrations",
    description: "Need a custom integration? We can build it for you. Contact us for more information.",
  }
]

export default function Example() {
  return (
    <div className="bg-indigo-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-white">Everything you need</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">All-in-one platform</p>
            <p className="mt-6 text-base leading-7 text-white">
              One platform to rule them all. CampaignHQ is an all-in-one platform for your email marketing and WhatsApp marketing needs.
            </p>
          </div>
          <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-6 text-base leading-7 text-white sm:grid-cols-2 lg:gap-y-7">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="font-semibold text-white">
                  <CheckIcon className="absolute left-0 top-1 min-h-5 w-5 text-white" aria-hidden="true" />
                  {feature.name}
                </dt>
                <dd className="mt-2">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
