import { DashboardLayout } from '@/components/layouts/DashboardLayout'
import { Discuss } from 'react-loader-spinner'
import { useRouter } from 'next/router'
import LandingPagev2 from '@/components/LandingPagev2'
import { NextSeo } from 'next-seo';

export default function Home() {
  const router = useRouter()
  return (
    <>
      <NextSeo
        title="Email Marketing Done Right | CampaignHQ"
        description="CampaignHQ.co is the email marketing tool you need to create, send, and track effective email campaigns that drive real results."
        canonical="https://campaignhq.co/"
        openGraph={{
          url: 'https://campaignhq.co/',
          title: 'Email Marketing Done Right | CampaignHQ',
          description: 'CampaignHQ.co is the email marketing tool you need to create, send, and track effective email campaigns that drive real results.',
          images: [
            {
              url: 'https://campaignhq-prod-public.s3.ap-southeast-1.amazonaws.com/ss-chq.png',
              width: 1200,
              height: 630,
              alt: 'CampaignHQ',
            },
          ],
        }}
        twitter={{
          handle: '@campaignhqco',
          site: '@campaignhqco',
          cardType: 'summary_large_image',
        }}
      />

      <LandingPagev2></LandingPagev2>
    </>
  )
}
