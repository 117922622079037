import Head from 'next/head'
import { NextSeo } from 'next-seo'
import Header from '@/components/landing-page/Header'
import Hero from '@/components/landing-page/Hero.jsx'
import Features from '@/components/landing-page/SecondaryFeatures'
import PrimaryFeatures from '@/components/landing-page/PrimaryFeatures'
import CallToAction from '@/components/landing-page/CallToAction'
import CallToAction2 from '@/components/landing-page/CallToAction2'
import Pricing2 from '@/components/landing-page/Pricing2'
import FAQ from '@/components/landing-page/FAQ'
import Footer from '@/components/landing-page/Footer'
import EmailProviders from '@/components/EmailProviders'
import Integrations from '@/components/landing-page/Integrations'
import Support from '@/components/landing-page/Support'
// import Zapier from '@/components/landing-page/Zapier';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentUser } from '@/store/sessionSlice'
import { useSession } from '@/hooks/useSession'
import Testimonials from '@/components/landing-page/Testimonials'
import { OrganizationJsonLd, BreadcrumbJsonLd, ArticleJsonLd } from 'next-seo'
import SignupCTAForm from '@/components/landing-page/SignupCTAForm'
import TalkToUsModal from '@/components/modals/TalkToUsModal'
import AllInOnePlatform from '@/components/landing-page/AllInOnePlatform'
import EmailAndWA from '@/components/landing-page/EmailAndWA'
import Channels from '@/components/landing-page/Channels'
import Pricing from '@/components/landing-page/Pricing'
import ContactSalesCTA from '@/components/landing-page/ContactSalesCTA'
import MainFeatures from '@/components/landing-page/Features'
import FeatureScroll from '@/components/landing-page/FeatureScroll'
import HoldOnCta from './landing-page/HoldOnCta'
export default function Home() {
  const dispatch = useDispatch()
  const { validate } = useSession()
  const currentUser = useSelector((state) => state.sessions.currentUser)
  const [openTalkToUsModal, setOpenTalkToUsModal] = useState(true)
  useEffect(() => {
    ;(async () => {
      await validate((user) => dispatch(setCurrentUser(user)))
    })()
    return () => {}
  }, [])

  return (
    <>
      
      <NextSeo
        title="Email & WhatsApp Marketing Automation - CampaignHQ"
        description="CampaignHQ is a multi channel marketing automation platform that helps you to send emails, WhatsApp messages."
        openGraph={{
          url: 'https://campaignhq.co/',
          title: 'Email & WhatsApp Marketing Automation - CampaignHQ',
          description: 'CampaignHQ is a multi channel marketing automation platform that helps you to send emails, WhatsApp messages.',
          images: [
            {
              url: 'https://campaignhq-prod-public.s3.ap-southeast-1.amazonaws.com/ss-chq.png',
              width: 1200,
              height: 630,
              alt: 'CampaignHQ',
            },
          ],
          site_name: 'CampaignHQ',
          locale: 'en_US',
          type: 'article',
        }}
        twitter={{
          handle: '@campaignhqco',
          site: '@campaignhqco',
          cardType: 'summary_large_image',
        }}

        additionalMetaTags={
          [
            {
              property: 'twitter:image',
              content: 'https://campaignhq-prod-public.s3.ap-southeast-1.amazonaws.com/ss-chq.png',
            },
            {
              property: 'twitter:image:alt',
              content: 'CampaignHQ - Email & WhatsApp Marketing Automation',
            }
          ]
        }
      />
      
      <Head>
        <title>
          Email & WhatsApp Marketing Automation - CampaignHQ
        </title>
        <meta
          name="description"
          content="CampaignHQ is a multi channel marketing automation platform that helps you to send emails, WhatsApp messages."
        />
      </Head>
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: 'CampaignHQ',
            item: 'https://campaignhq.co/',
          }
        ]}
      />
      <OrganizationJsonLd
        url="https://campaignhq.co/"
        logo="https://campaignhq-prod-public.s3.ap-southeast-1.amazonaws.com/ss-chq.png"
        name="CampaignHQ"
        description="CampaignHQ is a multi channel marketing automation platform that helps you to send emails, WhatsApp messages."
        sameAs={[
          'https://www.facebook.com/campaignhq.co',
          'https://twitter.com/campaignhqco',
          'https://www.linkedin.com/company/campaignhq',
        ]}
        address={{
          streetAddress: '1404 T-6,Salarpuria',
          addressLocality: 'Bangalore',
          addressRegion: 'Karnataka',
          postalCode: '560103',
          addressCountry: 'India',
        }}
      />

      <main>
        <Header user={currentUser} withNav={true}/>
        <Hero user={currentUser} />
        <Integrations />
        <MainFeatures />
        <FeatureScroll />
        {/* <EmailAndWA/> */}
        {/* <AllInOnePlatform /> */}
        <Channels />
        <SignupCTAForm />
        <Pricing />
        <HoldOnCta />
        
        {/* <PrimaryFeatures /> */}
        
        {/* <Features /> */}
        {/* <Pricing2 /> */}

        {/* <Support /> */}
        <Testimonials></Testimonials>
        
        {/* <ContactSalesCTA /> */}
        {/* <EmailProviders></EmailProviders> */}
        {/* <Zapier></Zapier> */}
        {/* <FAQ /> */}
      </main>
      {/* <TalkToUsModal
        open={openTalkToUsModal}
        setOpen={setOpenTalkToUsModal}
      ></TalkToUsModal> */}
      <Footer />
    </>
  )
}
