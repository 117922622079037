import { CheckIcon } from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Contact management',
    description: 'Manage your contacts in a single place. Import your contacts from more than 5000+ apps.',
  },
  { name: 'Email templates',
    description: 'Create beautiful emails with our drag-and-drop email builder. Use our email templates to get started quickly.',
  },
  {
    name: 'Email campaigns',
    description: 'Send your emails to your contacts. Track your open rates, click rates, and more.',
  },
  {
    name: 'Worflows and automation',
    description: 'Send your emails at the right time with our email automation.',
  },
  {
    name: "Transactional emails",
    description: "Send your transactional emails through our SMTP relay.",
  },{
    name: "Email deliverability assistance",
    description: "Get your emails delivered to your customers' inbox with our deliverability assistance.",
  },
  {
    name: "Custom integrations",
    description: "Need a custom integration? We can build it for you. Contact us for more information.",
  },{
    name: 'API integrations',
    description: 'Integrate with your favorite tools like Shopify, Zoho and 5000+ apps through zapier. Build your own integrations with our API.',
  },
  {
    name: 'Contact management',
    description: 'Manage your contacts in a single place. Import your contacts from more than 5000+ apps.',
  },
  { name: 'WhatsApp message templates',
    description: 'Manage your WhatsApp message templates in a single place.',
  },
  {
    name: 'WhatsApp campaigns',
    description: 'Bulk send your WhatsApp messages to your contacts. Track your read rates, click rates, and more.',
  },
  {
    name: 'Worflows and automation',
    description: 'Send your messages at the right time with our email automation.',
  },
  
]

export default function Example() {
  return (
    <div className="bg-indigo-900 py-24 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <h2 className="text-3xl font-semibold text-gray-300 leading-7 text-white text-center">Flexible & Affordable</h2>
      <p className="mt-6 text-xl leading-7 text-gray-300 text-center mb-16">
        Companies switching to CampaignHQ save up to 50% on their marketing automation costs.
      </p>
      <hr className='my-6 border-gray-600'/>
        <div className="mx-auto grid grid-cols-2 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none">
          <div className='bg-indigo-600 p-7 rounded'>
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Emails</p>
            <p className="mt-6 text-base leading-7 text-gray-300">
              Send email campaigns to your contacts on your own terms, connect your own ESP or use our SMTP relay to send your emails.
            </p>
            <hr className='my-6 border-gray-600'/>
            <ul className="mt-6 flex flex-col space-y-3">
              {features.slice(0, 8).map((feature) => (
                <li key={feature.name} className="relative">
                  <div className="flex items-start space-x-3">
                    <div className="flex-shrink-0">
                      {/* <CheckIcon className="h-5 w-5 text-gray-300" aria-hidden="true" /> */}
                    </div>
                    <p className="text-base leading-6 text-gray-300">{feature.name}</p>
                  </div>
                </li>
              ))}
            </ul>
            {/* <a href="" target="_blank" className="mt-6 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-500 hover:bg-green-600">
              Learn more
            </a> */}
          </div>
          <div className='bg-green-600 p-7 rounded'>
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">WhatsApp</p>
            <p className="mt-6 text-base leading-7 text-gray-300">
              Connect your WhatsApp account and send WhatsApp messages to your contacts, track your read rates, click rates, and more.
            </p>
            <hr className='my-6 border-gray-600'/>
            <ul className="mt-6 flex flex-col space-y-3">
              {features.slice(8, 12).map((feature) => (
                <li key={feature.name} className="relative">
                  <div className="flex items-start space-x-3">
                    <div className="flex-shrink-0">
                      {/* <CheckIcon className="h-5 w-5 text-gray-300" aria-hidden="true" /> */}
                    </div>
                    <p className="text-base leading-6 text-gray-300">{feature.name}</p>
                  </div>
                </li>
              ))}
            </ul>
            {/* <a href="" target="_blank" className="mt-6 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-500 hover:bg-green-600"> 
              Learn more
            </a> */}
          </div>
          
        </div>
      </div>
    </div>
  )
}
