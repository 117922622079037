import { EnvelopeIcon, } from '@heroicons/react/24/outline'
import { WaIcon } from '@/components/core/icons/WaIcon'
import { AwsIcon } from '@/components/core/icons/Aws'

const features = [
  {
    name: 'Email marketing',
    description:
      'Use our drag-and-drop email builder to create beautiful emails. Send your emails to your contacts. Track your open rates, click rates, and more. Send your emails at the right time with our email automation.',
    href: '/features/email-marketing/',
    icon: EnvelopeIcon,
    iconBg: 'bg-indigo-500',
  },
  {
    name: 'WhatsApp marketing',
    description:
      'Bulk send your WhatsApp messages to your contacts. Track your read rates, click rates, and more. Manage your WhatsApp message templates in a single place.',
    href: '/features/whatsapp-marketing-for-business/',
    icon: WaIcon,
    iconBg: 'bg-green-500',
  },
  {
    name: 'AWS SES Connect',
    description:
      'Have your own AWS SES account? Connect your AWS SES account to CampaignHQ and send your emails through your own AWS SES account.',
    href: '/features/aws-ses-connect/',
    icon: AwsIcon,
    iconBg: 'bg-gray-200',
  },
]

export default function Example() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            More channels more customers
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Using multiple channels to reach your customers is the best way to get more customers. CampaignHQ supports email marketing and WhatsApp marketing.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className={`mb-6 flex h-10 w-10 items-center justify-center rounded-lg ${feature.iconBg}`}>
                    <feature.icon className="h-8 w-8 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                  <p className="mt-6">
                    <a href={feature.href} className="text-sm font-semibold leading-6 text-indigo-600">
                      Learn more <span aria-hidden="true">→</span>
                    </a>
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
